<template>
  <div class="content">
    <div class="header text-center item-title">{{detail.title}}</div>
    <div class="margin-t-sm">
      <el-card class="box-card">
        <div v-for="(pages,index) in detail.pages" :key="index" class="text item annotation-title">
          <el-tag type="info">第{{pages.page_count}}页</el-tag>
          <div v-for="(item,index) in pages.contents" :key="index" class="text item annotation-content">
				 <div class="content annotation-word" v-if="item.type === 1">
					  {{item.content}}
			     </div>
				 <div v-else class="margin-t-sm">
					  <span class="demonstration">插图</span>
					  <div class="block">
					     <el-image
					       style="width: 100px; height: 100px"
					       :src="path + item.content"
					       fit="contain"
						   lazy
						   :preview-src-list="[path + item.content]"
						   >
						   <div slot="error" class="image-slot">
						        <i class="el-icon-picture-outline"></i>
						   </div>
						   </el-image>
					   </div>
				 </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
  name: "detail",
  data(){
    return {
      id: 0,
      detail: [],
	  path: config.imageUrl,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    ...mapActions('annotation',['getAnnotationDetail']),
    async getDetail(){
      var vm = this
      const { res_info, data } = await this.getAnnotationDetail({id: this.id})
      if (res_info !== 'ok') return
      vm.detail = data
    }
  }
}
</script>
<style scoped="scoped">
	.box-card {
		background: transparent;
		border: 0.0625rem #909399 solid;
	}
	.annotation-title {
		border-bottom: 0.0625rem #909399 solid;
	}
	.annotation-title:last-child {
		border: none;
	}
	.annotation-content {
		min-height: 12.5rem;
		font: 0.8125rem;
		color: #303133;
		padding-left: 1.25rem;
		margin: 0.03125rem auto;
		border-top: 0.0625rem #666666 dashed;
	}
	.annotation-word  {
		color: #606266;
		font-size: 0.8125rem;
	    padding-top: 0.625rem;
		line-height: 1.875rem;
	}
</style>
